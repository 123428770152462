#root {
  height: 100vh;
}

.header-notification {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 20px;
  }

  & > .notification-bell {
    font-size: 20px;
    cursor: pointer;
  }
}

.ant-table {
  & .ant-table-tbody {
    & .anticon {
      font-size: 20px;
      padding: 0 4px;
    }
  }
}
.ant-table-pagination.ant-pagination {
  margin-bottom: 0;
  align-items: center;
}
.event-header-image {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.event-header-image.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.event-form {
  .ant-form-item-label {
    padding: 0;
    margin: 0;
  }
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
  > .ant-message-custom-content .ant-message-info {
    height: 36px;
    display: flex;
    align-items: center;
  }
}

.ant-message-notice-content {
  .ant-message-custom-content {
    font-size: 16px;
  }
}

.icon-btn {
  display: flex;
  align-items: center;
}

.upload-image-container {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.upload-image-container.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.custom-icons-list {
  margin-right: 10px;
}

.ant-table-column-sorters {
  padding: 0;
}
.ant-table-thead > tr > th::before {
  display: none;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border: none !important; // due to inline styling important is given
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  padding: 0;
  margin: -1px 20px 0;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 16px 24px;
}
.login-content {
  padding: 35px;
  width: 100%;
}

.ant-input-prefix {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-password {
  text-align: center;
}

.ant-btn-sm {
  line-height: 0;
}

.attendee-details {
  .ant-form-item {
    min-width: 215px;
    margin-right: 16px;
  }
}

.rsvp-modal {
  .ant-modal-footer {
    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.ant-input-suffix {
  display: flex;
  align-items: center;
}

.option-input {
  border: unset;
  border-bottom: 1px solid @grey-5;
  border-radius: 0;

  &:focus {
    border: unset;
    border-bottom: 1px solid @grey-5;
    border-radius: 0;
    box-shadow: unset;
  }

  &:hover {
    border-color: transparent;
    border-right-width: 0;
    border: unset;
    border-bottom: 1px solid @grey-5;
    border-radius: 0;
  }
}
.ant-input-affix-wrapper,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 40px;
}
.option-input.ant-input-affix-wrapper-focused {
  box-shadow: unset;
}

.pointer {
  cursor: pointer;
}

.donation-list {
  overflow: auto;
}

.donation-card {
  border: 1px solid @light-orange;
  padding: 14px 32px;
  border-radius: 4px;
  margin-right: 24px;
  cursor: pointer;
  cursor: pointer;
  min-width: 200px;
  text-align: center;
  min-height: 100px;
  margin-bottom: 12px;
}

.donation-card.active {
  background-color: @peach;
}

.ministry-modal {
  height: 500px;

  .ant-modal-body {
    padding: 0;
  }

  object {
    min-height: 500px;
  }
}

.dialog {
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > button:first-child {
      margin-right: 0px;
    }
  }
}

.action-icons {
  display: flex;
  align-items: center;

  button:first-child {
    margin-right: 12px;
  }

  > button {
    margin-bottom: 0;
  }
}

//CUSTOM STYLE CLASSES START
.break-word {
  word-break: break-word;
}
//margin & padding style
.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}
.mt-20 {
  margin-top: 20px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-25 {
  margin-right: 25px;
}

.ml-5 {
  margin-left: 5px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-15 {
  margin-left: 15px;
}

.fs-x-large {
  font-size: x-large;
}
.fs-14 {
  font-size: 14px;
}

.p-0 {
  padding: 0;
}

.p-16 {
  padding: 16px;
}
//margin & padding style

// flex-box related style
.d-flex {
  display: flex;
}
.d-none {
  display: none;
}
.vertically-middle {
  vertical-align: middle;
}
.flex-horizontal {
  flex-direction: row;
}

.flex-vertical {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-2 {
  flex-shrink: 2;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-self-end {
  align-self: flex-end;
}
// flex-box related style

//width classes
.width-percent-20 {
  width: 20%;
}

.width-percent-50 {
  width: 50%;
}
.width-percent-100 {
  width: 100%;
}
.width-percent-60 {
  width: 60%;
}

.width-percent-40 {
  width: 40%;
}

.width-percent-5 {
  width: 5%;
}

.width-percent-25 {
  width: 25%;
}

.fill-width {
  width: 100%;
}

.width-200 {
  width: 200px;
}
//width classes

.fill-height {
  height: 100%;
}

//cursor
.pointer {
  cursor: pointer;
}
//cursor

//border style
.b-0 {
  border: none;
}
//border style

.ant-popover-title {
  display: flex;
  justify-content: center;
}
//CUSTOM STYLE CLASSES END

.gx-user-popover {
  li {
    span {
      margin-right: 15px;
    }
  }
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  & button {
    margin-right: 10px;
    border: 1px solid @border-color;
    font-weight: 500;
  }

  .cancel-button {
    background: @light-blue;
    border-radius: 40px;
    color: @grey-color;
    border-color: @light-blue;
  }

  .save-button {
    background: @primary-color;
    color: @white-color;
  }
}

.gx-login-content .ant-form .ant-form-item:first-of-type {
  margin-bottom: 10px;
}
.set-password-form {
  .password-input {
    .ant-form-item-explain,
    .ant-form-item-explain-connected {
      display: none;
    }
  }
}
.password-text {
  width: 100%;
  margin: 0px 0px 25px 0px;
  .ant-checkbox-group {
    flex-direction: column;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @active-tag-color;
  }
  .ant-checkbox-disabled + span {
    color: @label-color;
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    display: flex;
    margin: 0px;
  }
}

.change-password-content {
  display: flex;
  justify-content: center;
}

.change-password-inside-content {
  max-width: 600px;
  width: 94%;
  margin-top: 25px;
  padding: 20px 20px 20px;
  background-color: @white-color;
  .border-radius(@border-radius-lg);
  .box-shadow(@gx-card-shadow);
  font-size: 14px;
  position: relative;
  z-index: 5;
  h1 {
    font-weight: 500;
    font-size: @h2-font-size;
    margin: 0;
    margin-bottom: 10px;
  }
  #change-password {
    .ant-row.ant-form-item {
      margin-bottom: 10px;
    }
  }
  .form-buttons {
    margin-top: 15px;
    .cancel-button:hover {
      background-color: #f2f4f7;
      color: @grey-color;
      border-color: #f2f4f7;
    }
  }
}

.ant-form-item-explain,
.ant-form-item-extra {
  margin-top: 5px;
  font-size: 12px;
}
.ant-btn,
.ant-tag {
  border: none;
  box-shadow: none;
}

.wrapper-404 {
  min-height: calc(100vh - 50px - 72px - 40px);
  min-width: auto;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .error-msg {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 6px;
  }
  .home-link {
    font-size: 20px;
  }
}

.ant-input,
.ant-select-selection-placeholder,
.ant-input-number-input,
.ant-picker-input > input {
  font-size: 14px;
}
.instruction-scroll-wrapper {
  overflow-y: auto;
  height: calc(100vh - 200px);
  min-height: 400px;
  overflow-x: hidden;
  &.image-list {
    .infinite-data-container {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -8px;
      width: auto;
    }
  }
}
.gallery-image-popover {
  .ant-popover-content {
    max-width: 310px;
    height: auto;
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner {
      background: @light-blue;
      .ant-descriptions-item-label,
      .ant-descriptions-item-content {
        font-size: 16px;
      }
      .ant-descriptions-item-content {
        color: #202a3f;
      }
    }
  }
}
.gallery-image {
  .ant-image-mask:hover {
    opacity: 0;
  }
  width: calc(20% - 16px);
  margin: 0 8px;
  position: relative;
  &:hover {
    .info-button {
      opacity: 1;
    }
  }
  @media screen and (max-width: 575px) {
    width: calc(40% - 16px);
  }
  @media screen and (min-width: 1440px) {
    width: calc(20% - 130px);
  }
  .ant-popover-arrow {
    display: none;
  }
  .ant-image {
    margin: 0 0 16px 0;
    width: 100%;
    .ant-image-img {
      border-radius: 8px;
      object-fit: cover;
    }
  }
  .ant-btn-icon-only {
    height: 24px;
    width: 24px;
    padding: 3px 0;
  }
  .info-button {
    position: absolute;
    right: 10px;
    top: 10px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.35s ease;
    @media screen and (max-width: 999px) {
      opacity: 1;
    }
  }
}
.infinite-loader {
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.8);
}
.alert-div {
  background-color: #fff1f0;
  display: flex;
  justify-content: center;
  padding: 5px 0px;
}
.action {
  margin-left: auto;
  svg {
    circle {
      fill: @white-color;
    }
  }
}
td.ant-table-cell {
  word-break: break-all;
}
.break-all-words {
  word-break: break-all;
}
.ant-typography {
  color: @black-color;
}
.text-primary {
  color: @primary-color;
}
.text-secondary {
  color: @grey-color;
}
.expand-text.ant-typography {
  margin-bottom: 0;
}
.ant-typography,
.ant-typography-ellipsis,
.expand-text {
  white-space: pre-wrap;
  word-break: break-word;
}
.disabled-button {
  svg {
    path {
      fill: @label-color;
    }
  }
}
.tag-beta.ant-tag {
  border-radius: 2px;
  font-size: 12px;
  line-height: 20px;
}

.position-relative {
  position: relative;
}

.collapsible-filter-wrapper {
  .ant-collapse,
  .ant-collapse-item,
  .ant-collapse-content {
    border: none;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: none;
  }
  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  .ant-col:nth-of-type(2n) {
    padding: 0;
  }
  .ant-col:nth-of-type(2n + 1) {
    padding-left: 0;
  }
  .ant-input,
  .ant-input-affix-wrapper,
  .common-dropdown-wrapper .ant-select .ant-select-selector {
    border-color: @border-color;
    height: 32px;
    display: flex;
    align-items: center;
  }
}
.common-scroll-wrapper {
  overflow-y: auto;
  height: calc(100vh - 276px);
  min-height: 400px;
}
.common-dropdown-wrapper {
  .ant-select.ant-select-single .ant-select-selector {
    border-radius: 40px;
  }
  .ant-spin-blur {
    opacity: 1;
  }
}

.infinite-data-container {
  top: 0;
  .ant-empty {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
.skeleton-card {
  border: 1px solid @border-color;
  padding: 16px;
}

.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: @grey-10;
  background: @white-color;
  border-color: @inactive-tag-background;
}

@media screen and (max-width: 999px) {
  .custom-date-picker-panel {
    .ant-picker-panel {
      &:last-child {
        width: 0;
        .ant-picker-header {
          position: absolute;
          right: 0;
          .ant-picker-header-prev-btn,
          .ant-picker-header-view {
            visibility: hidden;
          }
        }
        .ant-picker-body {
          display: none;
        }
      }
    }
    .ant-picker-footer-extra {
      line-height: 18px;
      padding: 8px 12px;
    }
  }
}

.ant-picker-dropdown .ant-picker-header-view {
  font-weight: 500;
  color: @heading-color;
}

.custom-date-picker-panel {
  .ant-picker-panel-layout {
    flex-direction: column-reverse;
    .ant-picker-presets {
      max-width: 100%;
      @media screen and (max-width: 999px) {
        max-width: 288px;
      }
      ul {
        height: auto;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border-right: none;
        border-top: var(--ant-line-width) var(--ant-line-type)
          var(--ant-color-split);
        gap: 16px;
        li {
          font-size: 12px;

          & + li {
            margin: 0;
          }
        }
      }
    }
  }
}

.filter-btn.ant-btn {
  border: none;
  padding: 0;
  background-color: #202a3f;
  height: 30px;
  min-width: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.has-select-all {
  .ant-select-selector {
    max-height: 200px;
    overflow: auto;
  }
}
.no-tag-render {
  .ant-select-selection-item {
    display: none;
  }
}
.image-count {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: @grey-10;
}

.gallery-info {
  z-index: 9999;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner-content {
    padding: 12px 16px;
  }
}
.text-danger {
  color: @danger-color;
}
.note-margin {
  margin: -10px 0 10px 0;
  font-size: 14px;
}
.country-code-phone-input {
  .ant-input-group-addon:first-child {
    padding: 0;
  }
  .ant-input {
    padding: 5px 10px;
  }
  .country-code-input {
    &.ant-form-item {
      margin-bottom: 0;
    }
    width: 80px;
    .ant-form-item-control-input {
      min-height: 30px;
    }
    .ant-select.ant-select-single {
      width: 100%;
    }
    .ant-select-selector {
      padding: 0;
    }
  }
}

.ant-pagination {
  li:not(.ant-pagination-options) {
    border: 1px solid @grey-5;
  }
  li.ant-pagination-item-active {
    border: 1px solid #ff784f;
  }
}

.ant-modal {
  .ant-modal-content {
    padding: 0;
    .ant-modal-header {
      padding: 16px 24px;
      color: #676c77;
      background: #fff;
      border-bottom: 1px solid #e8e8e8;
      border-radius: 6px 6px 0 0;
      margin: 0;
      .ant-modal-title {
        font-weight: 500;
      }
    }
    .ant-modal-body {
      padding: 24px;
      color: #676c77;
      background: #fff;
      border-bottom: 1px solid #e8e8e8;
      border-radius: 6px 6px 0 0;
      margin: 0;
      p {
        margin-top: 0;
      }
      h2 {
        margin: 0;
        margin-bottom: 10px;
      }
    }
  }
}

.ant-form-item-explain-error {
  font-size: 12px;
}

.ant-popover-content .ant-popover-inner {
  padding: 0;
}

.bg-transparent {
  background: transparent;
}

.ant-picker {
  padding: 7px 11px;
  .ant-picker-input > input {
    font-size: 14px;
    height: 18px;
  }
}

.mobile-layout {
  flex: 1 1;
}

[hidden] {
  display: none;
}

.not-found {
  .gx-error-code {
    color: @grey-color;
  }
  .gx-btn-primary {
    color: @dark-orange-color;
  }
}

.header-portal-wrapper {
  .buttons {
    #header-right-content {
      .form-buttons > button:nth-child(1):hover {
        background-color: transparent;
        color: @grey-color;
      }
      .form-buttons > button:nth-child(2):hover {
        background-color: @dark-orange-color;
        color: @white-color;
      }
    }
  }
}

::selection {
  background: #fc5027;
  color: white;
}

.ant-picker-dropdown.ant-picker-dropdown-placement-bottomRight
  .ant-picker-range-arrow {
  display: none;
}
.ant-tooltip .ant-tooltip-inner {
  background-color: rgba(0, 0, 0, 0.75);
}
